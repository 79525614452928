/** @format */

import Tooltip from '@atoms/Tooltip/Tooltip';
import TooltipButton from '@atoms/TooltipButton';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';

interface TooltippedLabelProps {
  label: EmotionJSX.Element;
  tooltip: EmotionJSX.Element;
}

const StyledTooltipLabel = styled.span`
  display: flex;
  gap: 6px;
  z-index: 9;
  align-items: center;
`;

const StyledTooltipIcon = styled.span`
  position: relative;
  display: flex;
  align-items: center;
`;

function TooltippedLabel(props: TooltippedLabelProps) {
  return (
    <StyledTooltipLabel>
      <span>{props.label}</span>
      <TooltipButton tooltip={props.tooltip}>
        <FontAwesomeIcon icon={['fas', 'question-circle']} style={{ height: '12px', cursor: 'pointer' }} />
      </TooltipButton>
    </StyledTooltipLabel>
  );
}

export default TooltippedLabel;
